* {
  box-sizing: border-box;
}

h1 {
  font-size: 22px;
  color: #363a40;
}

h2 {
  color: #363a40;
}

p {
  font-size: 12px;
  color: #9d9fa3;
  font-weight: 100;
}

ul {
  padding: 0;
  margin: 0;
}

li {
  list-style: none;
}

::-webkit-input-placeholder {
  /* Edge */
  color: #d3d3d5;
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #d3d3d5;
}

::placeholder {
  color: #d3d3d5;
}
